<template>
  <span>
    <v-data-table
      :loading="loading || localLoading"
      :headers="computedHeaders"
      :disable-sort="!true"
      :items="items"
      class="dataTable elevation-1"
      :search="search"
      mobile-breakpoint="0"
      :dense="true"
    >
      <template v-slot:header.source_address="{ header }">
        <span class="d-flex flex-row align-center">
          <v-img
            :src="require('../../../assets/IconOriginMap.png')"
            style="max-width: 20px"
          ></v-img>
          {{ header.text }}
        </span>
      </template>
      <template v-slot:header.destination_address="{ header }">
        <span class="d-flex flex-row align-center">
          <v-img
            :src="require('../../../assets/IconDestinationMap.png')"
            style="max-width: 20px"
          ></v-img>
          {{ header.text }}
        </span>
      </template>
      <template v-slot:top>
        <v-row
          dense
          class="
            d-flex
            flex-column flex-md-row
            align-center
            justify-md-space-between
          "
        >
          <v-col
            md="4"
            cols="12"
            class="d-flex flex-row align-center mt-2"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : 'pa-2'"
          >
            <v-text-field
              :label="$t('Search')"
              :prepend-inner-icon="icons.mdiMagnify"
              outlined
              dense
              v-model="search"
              clearable
              @keyup.esc="search = ''"
              :disabled="loading"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="
              d-flex
              flex-row
              justify-end
              align-center
              mb-2
              color-blue-text
            "
          >
            <span class="ml-2">
              <v-tooltip top :max-width="300">
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="primary"
                    @click="handleRefresh()"
                  >
                    <v-icon>
                      {{ icons.mdiRefresh }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("Refresh table") }}
                </span>
              </v-tooltip>
            </span>
          </v-col>
        </v-row>
      </template>
      <template #item.trip_comment="{ item }">
        <td class="text-start">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <span class="truncate-text d-block" v-bind="attrs" v-on="on">
                {{ item.trip_comment }}
              </span>
            </template>
            {{ item.trip_comment }}
          </v-tooltip>
        </td>
      </template>
      <template #item.destination_address="{ item }">
        <td v-if="item.is_multi_stop" class="d-flex flex-row align-baseline">
          {{ item.destination_address }}
          <span @click="handleMultiStopModal(item)" class="cursorPointer">
            <v-badge
              right
              color="warning"
              :content="item.routes.length"
              offset-y="10"
            >
            </v-badge>
          </span>
        </td>
        <td v-else>
          {{ item.destination_address }}
        </td>
      </template>
      <template #item.unique_id="{ item }">
        <span>
          <td>
            <a target="_blank" :href="`/view-map/${item._id}`">
              {{ item.unique_id }}
            </a>

            <span v-if="item.is_multi_stop">
              <br />
              <v-chip
                color="primary"
                small
                outlined
                @click="handleMultiStopModal(item)"
              >
                <v-icon left small>
                  {{ icons.mdiMapMarkerPath }}
                </v-icon>
                {{ $t("multi_stop") }}
              </v-chip>
            </span>
          </td>
        </span>
      </template>
      <template #item.user_name="{ item }">
        <a
          target="_blank"
          :href="`/user/${item.user_id}`"
          v-if="item.is_corporate_user"
        >
          {{ item.user_name }}
        </a>
        <span v-else> {{ item.user_name }} </span>
        <br />
        <a :href="`tel:${item.user_phone}`">
          {{ item.user_phone.substring(3) }}
        </a>
      </template>
      <template #item.total="{ value }">
        {{ value | currency }}
      </template>
      <template #item.provider_name="{ item }">
        <span v-if="!item.provider_name">{{ $t("No driver") }}</span>
        <a v-else target="_blank" :href="`/provider/${item.provider_id}`">
          {{ item.provider_name }}
        </a>
      </template>
      <template #item.date="{ item }">
        <span v-if="!!item.server_start_time_for_schedule">
          {{
            item.server_start_time_for_schedule | moment("DD-MM-YYYY h:mm a")
          }}
        </span>
        <span v-else>
          {{ item.created_at | moment("DD-MM-YYYY h:mm a") }}
        </span>
      </template>
      <template #item.trip_status="{ item }">
        <v-chip
          v-if="
            item.trip_status === 'Waiting' &&
            !item.server_start_time_for_schedule
          "
          class="v-chip-light-bg error--text"
          color="accent"
        >
          {{ $t(item.trip_status) }}</v-chip
        >
        <v-chip
          v-if="
            item.trip_status === 'Waiting' &&
            item.server_start_time_for_schedule
          "
          class="v-chip-light-bg primary--text"
          color="primary"
        >
          {{ $t("Scheduled") }}</v-chip
        >
        <v-chip
          v-else-if="item.trip_status === 'Accepted'"
          class="v-chip-light-bg warning--text"
          color="warning"
        >
          {{ $t(item.trip_status) }}</v-chip
        >
        <v-chip
          v-else-if="item.trip_status === 'Coming'"
          class="v-chip-light-bg warning--text"
          color="warning"
        >
          {{ $t(item.trip_status) }}</v-chip
        >
        <v-chip
          v-else-if="item.trip_status === 'Arrived'"
          class="v-chip-light-bg success--text"
          color="success"
        >
          {{ $t(item.trip_status) }}</v-chip
        >
        <v-chip
          v-else-if="item.trip_status === 'Started'"
          class="v-chip-light-bg success--text"
          color="success"
        >
          {{ $t(item.trip_status) }}</v-chip
        >
        <v-chip
          v-else-if="item.trip_status === 'Driver Waiting'"
          class="v-chip-light-bg warning--text"
          color="success"
        >
          {{ $t(item.trip_status) }}</v-chip
        >
        <v-chip v-else>
          {{ $t(item.trip_status) }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu rounded=".rounded-lg" offset-y>
          <template v-slot:activator="{ attrs, on }">
            <!-- class="white--text ma-5" -->
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item link @click="handleShowUser(item)">
              <v-list-item-title>{{ $t("View user") }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="handleShowProvider(item)"
              v-if="item.provider_name"
            >
              <v-list-item-title>{{ $t("View provider") }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="handleViewMap(item)">
              <v-list-item-title>{{ $t("View map") }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="handleShareTrip(item)">
              <v-list-item-title>{{
                $t("copy_trip_details")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              v-if="item.trip_status !== 'Started'"
              @click="handleCancel(item)"
            >
              <v-list-item-title>{{ $t("Cancel trip") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <!-- <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize"> Reset </v-btn>
                </template> -->

      <template #item.serviceType="{ value }">
        <span
          class="d-flex flex-column justify-center align-center mt-2"
          v-if="value"
        >
          <v-tooltip
            top
            content-class="bs-tooltip-top arrow"
            :max-width="300"
            bottom
          >
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                class="d-flex flex-column justify-center align-center"
              >
                <v-img
                  :src="`${bucketUrl}${value.type_image_url}`"
                  max-width="60"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row> </template
                ></v-img>
                <span class="fontUrbanist font-weight-bold">{{
                  value.typename
                }}</span>
              </span>
            </template>
            <span class="d-flex flex-row justify-center align-center">
              <v-icon
                x-small
                :style="!$vuetify.theme.isDark && 'color: #ffffff !important;'"
              >
                {{ icons.mdiAccount }}
              </v-icon>
              <span class="ml-2">
                {{ value.max_space }}
              </span>
              <span class="ml-2"> | </span>
              <v-icon
                x-small
                class="ml-2"
                :style="!$vuetify.theme.isDark && 'color: #ffffff !important;'"
              >
                {{ icons.mdiBagSuitcase }}
              </v-icon>
              <span class="ml-2">
                {{ value.baggages }}
              </span>
            </span>
          </v-tooltip>
        </span>
        <span v-else>
          <!-- <v-progress-circular indeterminate /> -->
        </span>
      </template>
    </v-data-table>
  </span>
</template>
<script>
import {
  mdiDotsVertical,
  mdiRefresh,
  mdiMagnify,
  mdiAccount,
  mdiBagSuitcase,
  mdiMapMarkerPath,
} from "@mdi/js";
import axios from "@/plugins/axios";
import MultiStopListModal from "@/views/Multistop/parts/MultiStopListModal.vue";

export default {
  components: {
    MultiStopListModal,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    headers: {
      required: true,
    },
    items: {
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiRefresh,
        mdiMagnify,
        mdiAccount,
        mdiBagSuitcase,
        mdiMapMarkerPath,
      },
      localLoading: false,
      search: "",
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
    };
  },
  methods: {
    async handleCancel(trip) {
      const res = await this.$dialog.warning({
        text: this.$t("Do you want to cancel the trip?"),
        actions: {
          false: "No",
          true: {
            text: this.$t("Yes"),
          },
        },
      });
      if (!!res) {
        this.localLoading = true;
        try {
          const { data } = await axios.post(
            `${process.env.VUE_APP_API_URL}/cancel_trip`,
            {
              user_id: `${trip.user_id}`,
              trip_id: `${trip._id}`,
              cancel_reason: "Corporate cancel",
            }
          );
          if (!!data.success) {
            this.$dialog.notify.success(this.$t("Trip cancelled successfully"));
            this.$emit("cancelledTrip");
          } else {
            throw new Error("Error cancelling trip");
          }
        } catch (error) {
          this.$dialog.notify.error(error.message);
        }
        this.localLoading = false;
      }
    },
    handleRefresh() {
      this.$emit("refresh");
    },
    handleShowUser(trip) {
      let route = this.$router.resolve({
        name: "user",
        params: { id: trip.user_id },
      });
      window.open(route.href, "_blank").focus();
    },
    handleShowProvider(trip) {
      let route = this.$router.resolve({
        name: "provider",
        params: { id: trip.provider_id },
      });
      window.open(route.href, "_blank").focus();
    },
    handleViewMap(trip) {
      let route = this.$router.resolve({
        name: "ViewMap",
        params: { id: trip._id },
      });
      window.open(route.href, "_blank").focus();
    },
    async handleMultiStopModal(trip) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "60%",
        transition: "dialog-top-transition",
        item: trip,
      };
      const dialogInstance = await this.$dialog.showAndWait(
        MultiStopListModal,
        params
      );
    },
    handleShareTrip(trip) {
      let route = this.$router.resolve({
        name: "TripInfo",
        params: { id: trip._id },
      });

      navigator.clipboard.writeText(process.env.VUE_APP_DOMAIN + route.href);
      this.$dialog.notify.success(this.$t("copy_trip_details_success"));
    },
    shouldTruncate(text) {
      // Esta función determina si el texto debe truncarse o no.
      // Puedes ajustar la lógica según tus necesidades.
      if (!text) return false;
      return text.length > 50; // Ejemplo: Truncar si el texto es mayor a 50 caracteres.
    },
  },
  computed: {
    computedHeaders() {
      let headers = this.headers;
      headers.map((head) => (head.text = this.$t(head.text)));
      return headers;
    },
  },
};
</script>
<style lang="scss">
.dataTable {
  tbody tr:nth-of-type(even) {
    background-color: rgba(58, 53, 65, 0.04);
  }

  tbody tr:hover {
    background-color: rgba(0, 5, 34, 0.08) !important;
  }

  tbody tr {
    border-bottom: hidden !important;
  }
}
</style>

<style lang="scss" scoped>
.truncate-text {
  max-width: 10rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
